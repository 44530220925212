import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import { renderRichText } from '../lib/richTextRender'
import Layout from '../components/layout'
import PageWrapper from '../components/pageWrapper'

class About extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const [about] = get(this, 'props.data.allContentfulAbout.edges')

    return (
      <Layout>
        <PageWrapper>
          <Helmet title={siteTitle} />
          <div className="p-3 pt-5">
            <h2 className="section-headline font-weight-bold">Über mich</h2>
          </div>

          <div className="wrapper d-flex pm-3 pl-3 pr-3 algin-items-center flex-wrap">
            <div style={{ flex: 1 }}>
              <Img
                style={{ minWidth: '200px', marginBottom: '1rem' }}
                fluid={about.node.portrt.fluid}
                alt={about.node.intro}
              />
              <p>{about.node.intro}</p>
              <hr />
              {renderRichText(about.node.whyText.json)}
            </div>
            <div style={{ flex: 1, marginLeft: '1rem' }}>
              {renderRichText(about.node.text.json)}
            </div>
          </div>
        </PageWrapper>
      </Layout>
    )
  }
}

export default About

export const pageQuery = graphql`
  query aboutQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulAbout {
      edges {
        node {
          text {
            json
          }
          whyText {
            json
          }
          intro
          portrt {
            fluid(maxWidth: 500, quality: 100) {
              ...GatsbyContentfulFluid_tracedSVG
            }
          }
        }
      }
    }
  }
`
